import React, { useContext } from "react";
import { NotificationContext } from "../context/NotificationContext";
import { Link, useLocation } from "react-router-dom";
import { AiFillDashboard } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaPowerOff, FaMoneyCheckAlt } from "react-icons/fa";
import { BsFillGearFill } from "react-icons/bs";
import { SiBetfair } from "react-icons/si";
import { MdAdminPanelSettings } from "react-icons/md";
import { MdSupervisedUserCircle } from "react-icons/md";
import { GiTestTubes } from "react-icons/gi";
import { FaTelegramPlane } from 'react-icons/fa';

function Menu({ t, isAuthenticated, isAdministrator, logout }) {
	const location = useLocation();
	const currentRoute = location.pathname;
    const { unreadBetNotifications } = useContext(NotificationContext);

	const onLogOut = () => {
		logout();
	};

	return (
		<nav id="menu">
			<Link to="/">
				<h1 id="logo">
					{isAuthenticated ? (
						<>
							<span className="light">B</span>SM
						</>
					) : (
						<>
							<span className="light">Bet</span>Set&Match
						</>
					)}
				</h1>
			</Link>

			{isAuthenticated ? (
				<div id="itemsContainer">
					<Link className={currentRoute === "/" ? "active" : ""} to="/">
						<div className="item">
							<AiFillDashboard />
							<span>{t("Tableau de bord")}</span>
						</div>
					</Link>

					{isAdministrator ? (
                        <Link className={currentRoute === "/betNotifications" ? "active" : ""} to="/betNotifications">
                            <div className="item unread-notification-wrapper">
                                <FaTelegramPlane />
                                {unreadBetNotifications > 0 && (
                                    <span className="unread-notification">
                                        {unreadBetNotifications}
                                    </span>
                                )}
                                <span>{t("Notifications")}</span>
                            </div>
                        </Link>
					) : null}

					<Link className={currentRoute.includes("/preset") ? "active" : ""} to="/presets">
						<div className="item">
							<BsFillGearFill />
							<span>{t("Paramétrage")}</span>
						</div>
					</Link>

					<Link className={currentRoute === "/bets" ? "active" : ""} to="/bets">
						<div className="item">
							<SiBetfair />
							<span>{t("Paris")}</span>
						</div>
					</Link>

					<Link className={currentRoute === "/backtest" ? "active" : ""} to="/backtest">
						<div className="item">
							<GiTestTubes />
							<span>{t("Back-test")}</span>
						</div>
					</Link>

					<Link className={currentRoute === "/subscription" ? "active" : ""} to="/subscription">
						<div className="item">
							<FaMoneyCheckAlt />
							<span>{t("Abonnement")}</span>
						</div>
					</Link>

					{isAdministrator ? (
						<Link className={currentRoute === "/administration" ? "active" : ""} to="/administration">
							<div className="item">
								<MdAdminPanelSettings />
								<span>{t("Administration")}</span>
							</div>
						</Link>
					) : null}

					{isAdministrator ? (
						<Link className={currentRoute === "/administration/users" ? "active" : ""} to="/administration/users">
							<div className="item">
								<MdSupervisedUserCircle />
								<span>{t("Utilisateurs")}</span>
							</div>
						</Link>
					) : null}

					<Link className={currentRoute === "/profile" ? "active" : ""} to="/profile">
						<div className="item">
							<CgProfile />
							<span>{t("Profil")}</span>
						</div>
					</Link>

					<Link className="atBottom" onClick={onLogOut} to="/">
						<div className="item">
							<FaPowerOff />
							<span>{t("Déconnexion")}</span>
						</div>
					</Link>
				</div>
			) : (
				<div id="itemsContainer">
					<div id="centralMenu">
						<Link className={currentRoute === "/valueBetting" ? "active" : ""} to="/valueBetting">
							{t("Value betting")}
						</Link>
						<Link className={currentRoute === "/pricing" ? "active" : ""} to="/pricing">
							{t("Tarifs")}
						</Link>
						<Link className={currentRoute === "/FAQ" ? "active" : ""} to="/FAQ">
							{t("FAQ")}
						</Link>
					</div>
					<div id="rightMenu">
						<Link className={currentRoute === "/login" ? "active" : ""} to="/login">
							{t("Connexion")}
						</Link>
						<Link className={currentRoute === "/register" ? "favourite active" : "favourite"} to="/register">
							{t("Essai gratuit")}
						</Link>
					</div>
				</div>
			)}
		</nav>
	);
}

export default Menu;
