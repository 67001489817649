import React from "react";
import { useTranslation } from 'react-i18next';

const CustomAlert = ({ title, message, onConfirm, onCancel }) => {
    const { t } = useTranslation();

    return (
        <div className="custom-alert-overlay">
            <div className="custom-alert-box">
                <h2 className="custom-alert-title">{title}</h2>
                <p className="custom-alert-message">{message}</p>
                <div className="custom-alert-buttons">
                    {onCancel && (
                        <button className="custom-alert-cancel" onClick={onCancel}>
                            {t("Annuler")}
                        </button>
                    )}
                    <button className="custom-alert-confirm" onClick={onConfirm}>
                        {t("OK")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomAlert;
