import React, { useState, useEffect, Fragment } from "react";
import { FaRegThumbsUp } from "react-icons/fa";
import { FaRegThumbsDown } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import { AiOutlineWarning } from "react-icons/ai";
import { TiCancel } from "react-icons/ti";
import { FaRegEdit } from "react-icons/fa";

const NotificationCard = ({ t, notification, onBan, onTake, onNotTaken }) => {
	const [isBanned, setIsBanned] = useState(false);
	const [isTaken, setIsTaken] = useState(false);
	const [isNotTaken, setIsNotTaken] = useState(false);
	const [editing, setEditing] = useState(false);
	const [showCancel, setShowCancel] = useState(false);
	const [selectingBookmaker, setSelectingBookmaker] = useState(false);

	const [selectedBook, setSelectedBook] = useState(null);
	const [customOdd, setCustomOdd] = useState(null);
	const [betAmount, setBetAmount] = useState(notification.betToPlace);
	const [countdown, setCountdown] = useState(5);
	const [countdownReseted, setCountdownReseted] = useState(false);

	const resetState = () => {
		setIsBanned(false);
		setIsNotTaken(false);
		setIsTaken(false);
		setCountdown(5);
		setShowCancel(false);
		setSelectingBookmaker(false);
		setEditing(false);
		setCountdownReseted(false);
	};

	useEffect(() => {
		if ((isBanned || isTaken || isNotTaken) && !countdownReseted) {
			const interval = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown <= 1) {
						clearInterval(interval);
						if (isBanned) onBan(notification.id);
						if (isNotTaken) onNotTaken(notification.id);
						if (isTaken) onTake(notification.id);
						return 0;
					}
					return prevCountdown - 1;
				});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [isBanned, isTaken, isNotTaken, countdownReseted]);

	const handleTake = () => {
		setSelectingBookmaker(true);
		setShowCancel(true);
	};

	const handleSelectBookmaker = (book) => {
		setSelectedBook(book);
		const selectedSoftValue = notification.softValues.find((softValue) => softValue.book === book);
		setCustomOdd(selectedSoftValue.odd);
		setBetAmount(notification.betToPlace);
		setIsTaken(true);
	};

	const handleEdit = () => {
		setCountdownReseted(true);
		setEditing(true);
	};

	const handleValidateEdit = () => {
		setCountdownReseted(false);
		setEditing(false);
		setCountdown(5);
	};

	const handleCancel = () => {
		resetState();
	};

	const handleBan = () => {
		setIsBanned(true);
		setShowCancel(true);
		setCountdown(5);
	};

	const handleNotTaken = () => {
		setIsNotTaken(true);
		setShowCancel(true);
		setCountdown(5);
	};

	return (
		<div className={`notification-card ${isBanned ? "banned" : ""}`}>
			{/* Top section */}
			<div className="notification-card__top">
				<p className="notification-card__top__title">
					{notification.sport} - {notification.competition}
				</p>
				<p className="notification-card__top__subtitle">
					<u>{t("Date")}</u> : {notification.date}
				</p>
				<p className="notification-card__top__subtitle">
					<u>{t("Opening")}</u> : {notification.opening}
				</p>
				<p className="notification-card__top__subtitle">
					<u>{t("Liquidité")}</u> : {notification.liquidity}€
				</p>
				<p className="notification-card__top__subtitle">
					<u>{t("Partagé le")}</u> :{" "}
					{new Date(notification.sharedDate).toLocaleDateString("fr-FR", {
						day: "2-digit",
						month: "2-digit",
						year: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					})}
				</p>
				{notification.advertiseToAdd && (
					<p className="notification-card__top__warning">
						<AiOutlineWarning style={{ verticalAlign: "text-bottom", fontSize: "18px" }} />
						{notification.advertiseToAdd.split("\n").map((line, index) => (
							<Fragment key={index}>
								{" "}
								{line}
								<br />
							</Fragment>
						))}
					</p>
				)}
			</div>

			{/* Middle section */}
			<div className="notification-card__middle">
				<p className="notification-card__middle__title">
					<strong>
						<u>{t("Paris")}</u> :{" "}
					</strong>
					{notification.bet}
				</p>
				<p className="notification-card__middle__category">
					<strong>
						<u>{t("Catégorie")}</u> :
					</strong>{" "}
					{notification.category}
				</p>
				<p className="notification-card__middle__market_label">
					<strong>
						<u>{t("Label")}</u> :
					</strong>{" "}
					{notification.marketLabel}
				</p>
				<p className="notification-card__middle__bet-amount">
					<strong>
						<u>{t("Mise")}</u> :
					</strong>{" "}
					<span>{notification.betToPlace}€</span>
				</p>
				<p className="notification-card__middle__fair-odd">
					<strong>
						<u>{t("FO")}</u> :
					</strong>{" "}
					{notification.fairOdd}
				</p>
			</div>

			{/* Bottom section */}
			<div className="notification-card__bottom">
				{selectingBookmaker && <h5>Pris sur ...</h5>}
				{notification.softValues.map((softValue, index) =>
					selectingBookmaker ? (
						<button key={index} className="notification-card__bottom__btn" onClick={() => handleSelectBookmaker(softValue.book)}>
							<span className="notification-card__bottom__link__book">{softValue.book}</span>
							<span>@ {softValue.odd}</span>
						</button>
					) : (
						<a key={index} href={softValue.url} target="_blank" rel="noopener noreferrer" className="notification-card__bottom__link">
							<span className="notification-card__bottom__link__book">{softValue.book}</span>
							<span>@ {softValue.odd}</span>
							<span className="notification-card__bottom__link__text">{t("TRJ")} : {softValue.trj}</span>
							<span className="notification-card__bottom__link__text">{t("EV")} : {softValue.ev}</span>
						</a>
					)
				)}
			</div>

			{/* Footer actions */}
			{!isBanned && !isTaken && !isNotTaken && !showCancel && !selectingBookmaker && (
				<div className="notification-card__footer">
					<button className="btn-take" onClick={handleTake}>
						<FaRegThumbsUp /> {t("J'ai pris")}
					</button>
					<button className="btn-not-taken" onClick={handleNotTaken}>
						<FaRegThumbsDown style={{ verticalAlign: "middle", fontSize: "20px" }} /> {t("Je n'ai pas pris")}
					</button>
					<button className="btn-ban" onClick={handleBan}>
						<FaTimes /> {t("Bannir")}
					</button>
				</div>
			)}

			{showCancel && (
				<div className="notification-card__footer">
					<button className="btn-cancel" onClick={handleCancel}>
						<TiCancel size={18} /> {t("Annuler")}
					</button>
				</div>
			)}

			{editing && (
				<div className="notification-card__edit">
					<div>
						<label>
							{t("Bookmaker")} :
							<select value={selectedBook} onChange={(e) => handleSelectBookmaker(e.target.value)}>
								{notification.softValues.map((softValue, index) => (
									<option key={index} value={softValue.book}>
										{softValue.book}
									</option>
								))}
							</select>
						</label>
						<label>
							{t("Côte")} :
							<input type="number" step="0.01" value={customOdd} onChange={(e) => setCustomOdd(e.target.value)} />
						</label>
						<label>
							{t("Mise")} :
							<input type="number" value={betAmount} onChange={(e) => setBetAmount(e.target.value)} />
						</label>
					</div>
					<button onClick={handleValidateEdit}>{t("Valider")}</button>
				</div>
			)}

			{isTaken && !editing && (
				<div className="notification-card__success">
					<p>
						{t("Vous avez pris le pari sur")} <span>{selectedBook}</span>, <span>{betAmount}€ </span>{t("misé pour une côte de")} <span>{customOdd}</span>.
					</p>
					<p>{t("Vous pouvez modifier ou annuler cette action avant la fin du décompte.")}</p>
					<p className="counter-wrapper">
						{t("Suppression de l'affichage dans")} <span>{countdown}</span> {t("seconde")}{countdown > 1 ? t("s") : ""}
					</p>
					<button className="btn-edit" onClick={handleEdit}>
						<FaRegEdit size={16} /> {t("Éditer")}
					</button>
				</div>
			)}

			{isBanned && (
				<div className="notification-card__banned">
					<p>
						{t("Ce pari va être banni. Vous pouvez annuler cette action avant la suppression.")}
					</p>
					<p className="counter-wrapper">
						{t("Suppression de l'affichage dans")} <span>{countdown}</span> {t("seconde")}{countdown > 1 ? t("s") : ""}
					</p>
				</div>
			)}

			{isNotTaken && (
				<div className="notification-card__not-taken">
					<p>
						{t("Ce pari n'a pas été pris. Vous pouvez annuler cette action avant la suppression.")}
					</p>
					<p className="counter-wrapper">
						{t("Suppression de l'affichage dans")} <span>{countdown}</span> {t("seconde")}{countdown > 1 ? t("s") : ""}
					</p>
				</div>
			)}
		</div>
	);
};

export default NotificationCard;
