import React, { createContext, useState, useRef, useEffect } from "react";
import { io } from "socket.io-client";

import CustomAlert from "../components/CustomAlert";

export const NotificationContext = createContext();

// const SOCKET_SERVER_URL = "http://localhost:3001/";
const SOCKET_SERVER_URL = "https://api.bet-set-match.com/";

const socket = io(SOCKET_SERVER_URL, {
	reconnection: true,
	reconnectionAttempts: 5,
	reconnectionDelay: 1000,
	reconnectionDelayMax: 5000,
	randomizationFactor: 0.5,
});

export const NotificationProvider = ({ children, isActive, userId }) => {
	const [notifications, setNotifications] = useState([]);
	const [unreadBetNotifications, setUnreadBetNotifications] = useState(0);
	const [alertOptions, setAlertOptions] = useState(null);
	const [isSocketConnected, setIsSocketConnected] = useState(false);
	const [isViewingNotifications, setIsViewingNotifications] = useState(false);
	const isViewingNotificationsRef = useRef(isViewingNotifications);

	useEffect(() => {
		isViewingNotificationsRef.current = isViewingNotifications;
	}, [isViewingNotifications]);

	const joinRoom = (storedUserId) => {
		if (storedUserId) {
			socket.emit("joinRoom", { userId: storedUserId });
			console.log(`Rejoint la room avec l'ID utilisateur : ${storedUserId}`);
		}
	};

	useEffect(() => {
		if (isActive) {
			socket.on("notification", (data) => {
				setNotifications((prevNotifications) => {
					const notificationExists = prevNotifications.some((n) => n.id === data.id);

					const updatedNotifications = notificationExists ? prevNotifications.map((n) => (n.id === data.id ? data : n)) : [...prevNotifications, data];

					const unseenNotifications = updatedNotifications.filter((n) => !n.isSeen);

					if (!isViewingNotificationsRef.current) {
						setUnreadBetNotifications(unseenNotifications.length);
					}

					return updatedNotifications;
				});
			});

			socket.on("connect", () => {
				console.log("Connecté au serveur avec l'ID :", socket.id);
				setIsSocketConnected(true);
				if (userId) {
					joinRoom(userId);
				}
			});

			socket.on("disconnect", (reason) => {
				console.log(`Déconnecté du serveur : ${reason}`);
				setIsSocketConnected(false);
				if (reason === "io server disconnect") {
					socket.connect();
				}
			});

			socket.io.on("reconnect_attempt", (attempt) => {
				console.log(`Tentative de reconnexion n°${attempt}...`);
			});

			socket.io.on("reconnect", (attempt) => {
				console.log(`Reconnecté après ${attempt} tentative(s).`);
				setIsSocketConnected(true);
				if (userId) {
					joinRoom(userId);
				}
			});

			socket.io.on("reconnect_failed", () => {
				setAlertOptions({
					title: "Erreur de connexion",
					message: "La reconnexion au système de notifications a échoué. Veuillez rafraîchir la page pour tenter de rétablir la connexion.",
					onConfirm: () => window.location.reload(),
					onCancel: () => setAlertOptions(null),
				});
			});

			joinRoom(userId);

			return () => {
				socket.off("connect");
				socket.off("notification");
				socket.off("disconnect");
				socket.io.off("reconnect_attempt");
				socket.io.off("reconnect");
				socket.io.off("reconnect_failed");
			};
		}
	}, [isActive, userId]);

	return (
		<NotificationContext.Provider
			value={{
				notifications,
				setNotifications,
				unreadBetNotifications,
				setUnreadBetNotifications,
				setIsViewingNotifications,
				isSocketConnected,
				socket,
			}}>
			{children}
			{alertOptions && <CustomAlert title={alertOptions.title} message={alertOptions.message} onConfirm={alertOptions.onConfirm} onCancel={alertOptions.onCancel} />}
		</NotificationContext.Provider>
	);
};
