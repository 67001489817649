import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { NotificationProvider } from "./context/NotificationContext";
import Home from './pages/Home';
import Menu from './components/Menu';
import Pricing from './pages/Pricing';
import Tools from './pages/Tools';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard.jsx';
import Profile from './pages/Profile';
import Subscription from './pages/Subscription';
import MyBets from './pages/MyBets';
import Administration from './pages/Administration';
import AdministrationUsers from './pages/administration/Users';
import BetNotifications from './pages/BetNotifications.jsx';
import Footer from './components/Footer';
import CGV from './pages/CGV';
import ML from './pages/ML';
import FAQ from './pages/FAQ';
import ValueBetting from './pages/ValueBetting';
import PaymentSuccess from './pages/PaymentSuccess';
import PaymentFailed from './pages/PaymentFailed';
import AccountVerification from './pages/AccountVerification';
import { useState, useEffect } from 'react';
import axios from 'axios'
import { Toaster } from "react-hot-toast";
import BackTest from './pages/BackTest';
import PresetDetails from './pages/PresetDetails';
import PresetsManagement from './pages/PresetsManagement';
import ScrollToTop from './ScrollToTop.js';

//axios.defaults.baseURL = 'http://localhost:3001/';
axios.defaults.baseURL = 'https://api.bet-set-match.com/';

function App() {
    const { i18n, t } = useTranslation();

    const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem("jwt") != null);
    const [isAdministrator, setIsAdministrator] = useState(false);
    const [JWToken, setJwt] = useState(localStorage.getItem("jwt"));
    const [user, setUser] = useState({});
    const [isAxiosReady, setIsAxiosReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("Chargement...");
    
    const logout = () => {
        localStorage.removeItem("jwt");
        localStorage.removeItem("user");

        setUser({});
        setJwt(null);
        setIsAuthenticated(false);
        setIsAdministrator(false);

        window.location.replace("/login");
    }

    const login = (token, user) => {
        localStorage.setItem("jwt", token);

        setCompleteUser(user);
        setJwt(token);
    }

    const setCompleteUser = (user) => {
        setUser(user);
        setIsAuthenticated(true);
        setIsAdministrator(user.grade === "administrator");

        localStorage.setItem("user", JSON.stringify(user));
    }

    useEffect(() => {
        checkAuthentication(JWToken, login, logout);
    }, []);

    useEffect(() => {
        axios.defaults.headers.common = {'Authorization': `Bearer ${JWToken}`};
        setIsAxiosReady(true);
    }, [JWToken]);

    const hasConfirmedAccount = () => user && user.isAccountConfirmed;
    const hasSubscription = () => user && (user.stripeFranceSubscriptionId || user.stripeWorldSubscriptionId || user.stripeWorldLiveSubscriptionId);
    const hasActiveSubscription = () => user && ((user.stripeFranceSubscriptionState === "active" || user.stripeFranceSubscriptionState === "trialing") || (user.stripeWorldSubscriptionState === "active" || user.stripeWorldSubscriptionState === "trialing") || (user.stripeWorldLiveSubscriptionState === "active" || user.stripeWorldLiveSubscriptionState === "trialing"));

    return (
        <div id="app" className={isAuthenticated ? "logged" : ""}>
            <div><Toaster/></div>

            <Router>
                <NotificationProvider isActive={isAuthenticated} userId={user?.id ? user.id : null}>
                    <ScrollToTop/>
                    <Menu t={t} isAuthenticated={isAuthenticated} isAdministrator={isAdministrator} logout={logout} />
                    { isLoading && <Loading loadingText={loadingText} /> }
                    {
                        isAxiosReady && isAuthenticated ?
                            hasConfirmedAccount()
                                ? hasActiveSubscription()
                                    ? ""
                                    : hasSubscription()
                                        ? <div id="noActiveSubscription">{t("Attention : votre abonnement n'est plus actif, aucune notification ne vous sera envoyée ! Pour renouveller votre abonnement, rendez-vous dans la section \"Abonnement\".")}</div>
                                        : <div id="noActiveSubscription">{t("Attention : vous ne vous êtes pas encore abonné, aucune notification ne vous sera envoyée ! Pour activer votre abonnement, rendez-vous dans la section \"Abonnement\".")}</div>
                                : <div id="noActiveSubscription">{t("Attention : la vérification de votre adresse email est un prérequis au démarrage de tout abonnement (période d'essai comprise).")}</div>
                            : ""
                            
                    }
                    <main>
                        { 
                            !isAuthenticated ? 
                            (
                                <Routes>
                                    <Route path="/" exact element={<Home t={t} isAxiosReady={isAxiosReady} Trans={Trans} />}/>
                                    <Route path="/pricing" exact element={<Pricing t={t} setIsLoading={setIsLoading} isAxiosReady={isAxiosReady}/>}/>
                                    <Route path="/tools" exact element={<Tools t={t}/>}/>
                                    <Route path="/contact" exact element={<Contact t={t} setIsLoading={setIsLoading} setLoadingText={setLoadingText}/>}/>
                                    <Route path="/login" exact element={<Login t={t} login={login} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/register" exact element={<Register t={t} login={login} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/valueBetting" exact element={<ValueBetting t={t} login={login} setIsLoading={setIsLoading} setLoadingText={setLoadingText} Trans={Trans} />}/>
                                    <Route path="/CGV" exact element={<CGV t={t}/>}/>
                                    <Route path="/ML" exact element={<ML t={t}/>}/>
                                    <Route path="/FAQ" exact element={<FAQ t={t} Trans={Trans}/>}/>
                                    <Route path="/paymentSuccess" exact element={<PaymentSuccess t={t}/>}/>
                                    <Route path="/paymentFailed" exact element={<PaymentFailed t={t}/>}/>
                                    <Route path="/accountVerify/:token" exact element={<AccountVerification t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="*" element={<Navigate t={t} to="/"/>}/>
                                </Routes>
                            ) : 
                            (
                                <Routes>
                                    <Route path="/" exact element={<Dashboard t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} user={user} />}/>
                                    { isAdministrator ? 
                                        <Route path="/betNotifications" exact element={<BetNotifications t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    : null }
                                    <Route path="/profile" exact element={<Profile t={t} isAxiosReady={isAxiosReady} logout={logout} user={user} setUser={setUser} setJwt={setJwt} setIsLoading={setIsLoading} setLoadingText={setLoadingText}/>}/>
                                    <Route path="/preset/:id" exact element={<PresetDetails t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/presets" exact element={<PresetsManagement t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/subscription" exact element={<Subscription t={t} isAxiosReady={isAxiosReady} user={user} hasActiveSubscription={hasActiveSubscription} setCompleteUser={setCompleteUser} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/bets" exact element={<MyBets t={t} isAxiosReady={isAxiosReady} user={user} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/backtest" exact element={<BackTest t={t} isAxiosReady={isAxiosReady} hasActiveSubscription={hasActiveSubscription} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    { isAdministrator ? 
                                        <Route path="/administration" exact element={<Administration t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    : null }
                                    { isAdministrator ? 
                                        <Route path="/administration/users" exact element={<AdministrationUsers t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} login={login} />}/>
                                    : null }
                                    <Route path="/tools" exact element={<Tools t={t}/>}/>
                                    <Route path="/valueBetting" exact element={<ValueBetting t={t} login={login} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="/CGV" exact element={<CGV t={t}/>}/>
                                    <Route path="/ML" exact element={<ML t={t}/>}/>
                                    <Route path="/FAQ" exact element={<FAQ t={t} Trans={Trans}/>}/>
                                    <Route path="/contact" exact element={<Contact t={t} setIsLoading={setIsLoading} setLoadingText={setLoadingText} user={user}/>}/>
                                    <Route path="/paymentSuccess" exact element={<PaymentSuccess t={t}/>}/>
                                    <Route path="/paymentFailed" exact element={<PaymentFailed t={t}/>}/>
                                    <Route path="/accountVerify/:token" exact element={<AccountVerification t={t} isAxiosReady={isAxiosReady} setIsLoading={setIsLoading} setLoadingText={setLoadingText} />}/>
                                    <Route path="*" element={<Navigate t={t} to="/"/>}/>
                                </Routes>
                            )
                        }
                    </main>
                    <Footer t={t} isAuthenticated={isAuthenticated} isAdministrator={isAdministrator} />
                </NotificationProvider>
            </Router>
        </div>
    );
}

export default App;

const Loading = ({loadingText}) => {
    return (
        <div id="loading">
            <div className="imgContainer">
                <img src="/images/loading.gif" alt="loading"/>
            </div>
            <p>{loadingText}</p>
        </div>
    )
}

const checkAuthentication = async (JWToken, login, logout) => {
    if (!JWToken)
        return;
    else {
        axios.get("user/authenticate", { headers : {'Authorization': `Bearer ${JWToken}`}})
        .then((result) => {
            if (result.status !== 200)
                logout();
            else 
                login(JWToken, result.data.user);
        })
        .catch(err => {
            logout()
        });
    }
}