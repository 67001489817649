import React, { useContext, useEffect } from "react";
import { NotificationContext } from "../context/NotificationContext";
import NotificationCard from "../components/NotificationCard";

function BetNotifications({ t, isAxiosReady, setIsLoading, setLoadingText, login }) {
	const { notifications, setNotifications, setUnreadBetNotifications, setIsViewingNotifications, isSocketConnected } = useContext(NotificationContext);

	const handleTake = (id) => {
		setNotifications((prev) => prev.filter((notification) => notification.id !== id));
	};

	const handleNotTaken = (id) => {
		setNotifications((prev) => prev.filter((notification) => notification.id !== id));
	};

	const handleBan = (id) => {
		setNotifications((prev) => prev.filter((notification) => notification.id !== id));
	};

	useEffect(() => {
		setIsViewingNotifications(true);

		setNotifications((prevNotifications) => {
			setUnreadBetNotifications(0);
			return prevNotifications.map((notification) => ({
				...notification,
				isSeen: 1,
			}));
		});

		return () => {
			setIsViewingNotifications(false);
		};
	}, []);

	return (
		<section id="section-notification">
			<h1>{t("Notifications")}</h1>
			<div className="notification-connection-status">
				<span className={`status-indicator ${isSocketConnected ? "connected" : "disconnected"}`}></span>
				<div className="status-text">
					<p>
						{t("Statut : ")} <span className="status-label">{isSocketConnected ? t("Connecté") : t("Déconnecté")}</span>
					</p>
				</div>
			</div>
			<div className="notifications-container">
				{notifications
					.slice()
					.sort((a, b) => new Date(b.sharedDate) - new Date(a.sharedDate))
					.map((notification) => (
						<NotificationCard key={notification.id} t={t} notification={notification} onBan={handleBan} onTake={handleTake} onNotTaken={handleNotTaken} />
					))}
			</div>
		</section>
	);
}

export default BetNotifications;
